'use strict';

document.addEventListener('DOMContentLoaded', function(){
	const basketList = document.querySelector('.js-basket');

	if(basketList) {
		const cost = basketList.dataset.cost,
					deliveryDiscount = basketList.dataset.deliveryDiscount,
					discount = basketList.dataset.discount,
					itemsSuite = basketList.querySelectorAll('[data-product-suite]'),
					itemsProduct = basketList.querySelectorAll('[data-product]'),
					basketBtnSubmit = document.querySelector('.js-basket-submit');


		let countAllItems = itemsSuite.length + itemsProduct.length;


		let suites = [];
		let products = [];

		function createProductsList() {
			products.length = 0;

			for(let i=0; i < itemsProduct.length; i++) {
				let count;

				if(itemsProduct[i].querySelector('.basket__counter')) {
					count = itemsProduct[i].querySelector('.basket__counter').dataset.counter;
				} else {
					count = itemsProduct[i].dataset.counter;
				}

				let productItem = new Object(
					{
						product: i,
						count: count,
						id: itemsProduct[i].dataset.id,
						cost: itemsProduct[i].dataset.cost
					}
				);
				products.push(productItem);
			};
		}

		function remomeProductItem(id) {
			for(var i = 0; i < products.length; i++) {
				if(products[i].id == id) {
						products.splice(i, 1);
						break;
				}
			}
		};

		function createSuitesList() {
			suites.length = 0;

			for(let i=0; i < itemsSuite.length; i++) {
				let count;

				if(itemsSuite[i].querySelector('.basket__counter')) {
					count = itemsSuite[i].querySelector('.basket__counter').dataset.counter;
				} else {
					count = itemsSuite[i].dataset.counter;
				}

				let suiteItem = new Object(
					{
						product: i,
						count: count,
						id: itemsSuite[i].dataset.id,
						cost: itemsSuite[i].dataset.cost
					}
				);
				suites.push(suiteItem);
			};
		}

		function remomeSuiteItem(id) {
			for(var i = 0; i < suites.length; i++) {
				if(suites[i].id == id) {
					suites.splice(i, 1);
						break;
				}
			}
		};

		if(itemsProduct) {
			createProductsList();
		}

		if(itemsSuite) {
			createSuitesList();
		}

		let data = {
			cost: cost,
			delivery_discount: deliveryDiscount,
			discount: discount,
			items: countAllItems,
			product_suites: suites,
			products: products
		};

		function setActiveSteps(resp) {
			const basketBody = document.querySelectorAll('.basket__body');

			basketBody.forEach(function(body) {
				body.classList.remove('basket__body--active');
			})

			basketBody[resp.step-1].classList.add('basket__body--active');


			const basketSteps = document.querySelectorAll('.basket__steps-item');

			basketSteps.forEach(function(body) {
				body.classList.remove('basket__steps-item--active');
			})

			basketSteps[resp.step-1].classList.add('basket__steps-item--active');

			for (var i = 0; i < [resp.step-1]; i++) {
				basketSteps[i].classList.add('basket__steps-item--passed');
			}
		}


		function sendBasketData() {
			const action = basketBtnSubmit.dataset.action;
			const state = basketBtnSubmit.textContent;

			let formData = new FormData();
			const stringifyData = JSON.stringify(data);
			formData.append('data', stringifyData);

			let request = new XMLHttpRequest();
			request.open('POST', action, true);
			request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');

			basketBtnSubmit.disabled = true;
			basketBtnSubmit.textContent = basketBtnSubmit.dataset.wait;

			request.onreadystatechange = function () {
				if (this.readyState != 4) return;

				var resp = JSON.parse(this.response);

				if(resp.step) {
					setActiveSteps(resp);
				}

				basketBtnSubmit.textContent = state;
				basketBtnSubmit.disabled = false;
			};

			request.send(formData);
		}


		basketBtnSubmit.addEventListener('click', function(e) {
			e.preventDefault();

			sendBasketData();
		})

		document.addEventListener('click', function(e){

			const tg = e.target;
			const item = tg.closest('.basket__list-item');

			if(tg.closest('[data-remove]')) {
				e.preventDefault();
				const id = item.dataset.id;

				item.remove();

				if(item.hasAttribute('data-product-suite')) {
					remomeSuiteItem(id);
				}

				if(item.hasAttribute('data-product')) {
					remomeProductItem(id);
				}

				sendBasketData();
			}

			if(tg.closest('[data-add]')) {
				e.preventDefault();
				const itemWrp = item.closest('.basket__list-item');

				if(itemWrp.hasAttribute('data-product-suite')) {
					createSuitesList();
				}

				if(itemWrp.hasAttribute('data-product')) {
					createProductsList();
				}

				sendBasketData();
			}

			if(tg.closest('.js-counter')) {
				e.preventDefault();

				if(item.hasAttribute('data-product-suite')) {
					createSuitesList();
				}

				if(item.hasAttribute('data-product')) {
					createProductsList();
				}

				sendBasketData();
			}

		})
	}

	// delivery
	const formDelivery = document.querySelector('.basket__delivery-form');

	document.addEventListener('click', function(e){
		const tg = e.target;

		if(tg.closest('.js-search')) {
			const searchWrp = tg.closest('.js-search'),
						searchInput = searchWrp.querySelector('.basket__delivery-finput'),
						searchDrop = searchWrp.querySelector('.basket__delivery-fdrop');

			searchInput.placeholder = 'Поиск...';
			searchWrp.classList.add('basket__delivery-fline--active');

			searchInput.addEventListener('input', function(){

				let data = new FormData();
				data.append(searchInput.name, searchInput.value);
				searchDrop.innerHTML = '<li class="basket__delivery-fditem">Загрузка...</li>'

				fetch(formDelivery.action, {
					method: 'POST',
					body: data,
					credentials: 'include',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
					},
				}).then(response => {
					return response.json();
				}).then(resp => {
			
					if (!resp.err) {
						searchDrop.innerHTML = resp.htmlSearchSelect;
					}
				}).catch(function(error) {
					console.error(error);
				});
			});
		}

		if(!tg.closest('.js-search')) {
			const openedSearch = document.querySelector('.basket__delivery-fline--active');

			if(openedSearch) {
				openedSearch.classList.remove('basket__delivery-fline--active');
			}
		}

		// choose search item
		if(tg.closest('.basket__delivery-fditem')) {
			const searchItem = tg.closest('.basket__delivery-fditem'),
						searchItemVal = searchItem.textContent,
						searchWrp = searchItem.closest('.js-search'),
						searchInput = searchWrp.querySelector('.basket__delivery-finput');

			searchInput.value = searchItemVal;

			if(searchWrp.classList.contains('basket__delivery-fline--active')) {
				searchWrp.classList.remove('basket__delivery-fline--active');
			}
		}
	});

	// payment
	const basketPayment = document.querySelector('.js-basket-payment');

	function applyAjaxResult(resp) {

		const askModalSuccess = document.querySelector('.popup--ask'),
					askModalSuccessText = askModalSuccess.querySelector('.popup__descr-wrp');
		askModalSuccess.classList.add('opened');
		document.body.style.overflow = 'hidden';

		if(resp.html) {
			askModalSuccessText.innerHTML = resp.html;
		} else {
			askModalSuccessText.querySelector('.popup__descr').innerText = resp.message;
		}

		submitBtn.textContent = state;
		submitBtn.disabled = false;
	}

	function sendBasketData() {
		const basketPaymentBtn = document.querySelector('.js-basket-payment-submit'),
					action = basketPaymentBtn.dataset.action,
					state = basketPaymentBtn.textContent,
					paymentType = document.querySelector('input.js-payment:checked').value;

		let data = {
			cost: basketPayment.dataset.cost,
			delivery_discount: basketPayment.dataset.deliveryDiscount,
			discount: basketPayment.dataset.discount,
			payment_type: paymentType
		};

		let formData = new FormData();
		const stringifyData = JSON.stringify(data);
		formData.append('data', stringifyData);

		let request = new XMLHttpRequest();
		request.open('POST', action, true);
		request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');

		basketPaymentBtn.disabled = true;
		basketPaymentBtn.textContent = basketPaymentBtn.dataset.wait;

		request.onreadystatechange = function () {
			if (this.readyState != 4) return;

			var resp = JSON.parse(this.response);

			basketPaymentBtn.textContent = state;
			basketPaymentBtn.disabled = false;

			applyAjaxResult(resp);
		};

		request.send(formData);
	}

	if(basketPayment) {
		const basketPaymentBtn = document.querySelector('.js-basket-payment-submit');
		
		basketPaymentBtn.addEventListener('click', function(){
			sendBasketData();
		})
	}


})